<template>
  <div id="desktop">
  </div>
</template>

<script>
export default {
    
}
</script>

<style scoped>
  #desktop{
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url("../assets/wallpaper.jpg");
    background-repeat: no-repeat, repeat;
    background-position: center;
    background-size: cover;
    background-color:rgb(30, 30, 30);
  }
</style>